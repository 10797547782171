import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectCategories from './selectcategories';

function LeadFilters(props) {
    const data = props.catdata && (props.catdata.length > 0) ? props.catdata : [];
    const [selectedCategories, setSelectedCategories] = useState([]);

    function selectCategory(e) {
        const eChecked = e.target.checked;
        const selectedCategory = e.currentTarget.dataset.value;
        let newSelectedCategories = [];
        
        if (eChecked) {
            newSelectedCategories = [...selectedCategories, selectedCategory]
        } else {
            newSelectedCategories = selectedCategories.filter(c => (c !== selectedCategory))
        }
        setSelectedCategories(newSelectedCategories);
        props.uscd(newSelectedCategories);
    }

    function clearSelectedCategories() {
        setSelectedCategories([]);
        props.uscd([]);
    }

    return (
        <div id="divCategoryContainer" className='p-1 border bg-light'>
            <Form.Group className="mb-3">
                <Form.Group as={Row} className="mb-1">
                    <Col>
                        <Form.Label id="lblCategories" className="d-flex h-100 align-items-center">
                            {`Categories`}{props.ucd ? (<React.Fragment>&nbsp;<i className="fa fa-spinner fa-pulse"></i></React.Fragment>) : ''}
                        </Form.Label>
                    </Col>
                    <Col className={`${(selectedCategories.length > 0) ? 'd-flex' : 'd-none'} justify-content-end`}>
                        <i className={`h6 my-auto bi bi-trash`} style={{cursor: "pointer"}} onClick={clearSelectedCategories}></i>
                    </Col>
                </Form.Group>
                <div id="divCategories" style={{height: "300px", overflowY: "auto",overflowX:"hidden"}} className="shadow border border-1 border-gray p-1" > 
                {data.map((row, index) => (
                    <Form.Check id={`cat-${index}`} >
                        <Form.Check.Input className="border border-1 border-primary" data-value={row.ucategory} checked={(selectedCategories.indexOf(row.ucategory) > -1)} onChange={selectCategory} />
                        <Form.Check.Label>{`${row.ucategory} (${row.count})`}</Form.Check.Label>
                    </Form.Check>
                ))}
                </div>
            </Form.Group>
        </div>
    )
}

export default LeadFilters;